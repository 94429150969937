import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Trail } from 'react-spring' 
import Helmet from 'react-helmet'
import socialImg from '../images/EditorialMeta.jpg'
import Layout from '../components/layout'
import '../components/directorList.scss'

const description = 'Our editorial team represents decades of experience in the industry and is supported by a team of talented producers and assistant editors. With a deep network across the post-production space and comfortable, modern edit suites, we can deliver your project to the finish line on schedule and in style.'

const editorsPage = ({data}) => {
  return (
    <Layout extraClasses={'editorial-page'}>
      <Helmet>
        <title>Picture North | Editor Roster</title>
        <meta property="og:title" content='Picture North | Editor Roster' />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={data.site.siteMetadata.siteUrl+socialImg} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={'picture_north'} />
        <meta name="twitter:title" content='Picture North | Editor Roster' />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={data.site.siteMetadata.siteUrl+socialImg} />
      </Helmet>
      <div className='directors-page-header text-center'>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Editorial</h1>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="editors-list">
        <Trail
          keys={data.editors.edges.map( ({node}) => node.frontmatter.title )}
          from={{opacity:0 }}
          to={{ opacity: 1 }}
        >
          {data.editors.edges.map( ({node}, i) => props => (
            <div style={props} key={node.id} className='editor-listing'>
              <Link className='dir-image-wrap' to={node.fields.slug} >
                <div className='editor-image' style={{backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})`}} ></div>
              </Link>
              <div className="dir-info">
                <Link to={node.fields.slug} >
                  <h2>{node.frontmatter.title}</h2>
                </Link>
              </div>
            </div>
          ))}
        </Trail>
      </div>
    </Layout>
  )
} 
export default editorsPage

export const editorsQuery = graphql`
  query {
    site {
      siteMetadata {
          siteUrl
      }
    }
    editors: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/editors/"}},sort: { order: ASC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
              slug
          }
          frontmatter {
            title
            subTitle
            expertise
            expObj
            cover {
              childImageSharp{
                fluid(maxWidth : 2000){
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`